.header-view {
	position: fixed;
	height: 90px;
	width: 100vw;
	top: 0;
	z-index: 9;
	background: linear-gradient(180deg, #0D0D0D 0%, #0D0D0D 0%, rgba(13, 13, 13, 0.00) 100%, rgba(13, 13, 13, 0.00) 100%);

	.logo {
		margin-left: 40px;
	}

	.navList {
		margin-right: 46px;

		.navItem {
			margin-left: 44px;
			color: rgba(255, 255, 255, 0.5);

			&:hover {
				cursor: pointer;
				color: #FFFFFF;
			}

			&:first-child {
				margin-left: 0;
			}
		}

		.navItemActive {
			color: #FFFFFF;
		}
	}
}

.menuItemActive,
.nav-trade {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 72px;
	height: 36px;
	background: #E4700E;
	border-radius: 9px;
	color: #FFFFFF !important;

	&:hover {
		background: #FC9741;
		transition: 0.1s;
	}
}

@media only screen and (max-width: 1080px) {
	.header-view {
		height: 72px;

		.logo {
			margin-left: 22px;
		}

		.navList {
			margin-right: 16px;

			.navItem {
				margin-left: 26px;
			}
		}
	}
}

.drawer-view {
	width: 100%;
	height: 100vh;
	background: #0D022F;

	.linkList,
	.close {
		margin-right: 40px;
	}

	.menu-list {
		width: 205px;
		height: 416px;
		background: url(../../assets/images/menuBg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding-top: 46px;
		padding-right: 40px;

		.menuItem {
			margin-top: 24px;
		}
	}

	.linkList {
		margin-top: 36px;
	}
}