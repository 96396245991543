.footer {
    position: relative;

    .footer-view {
        padding-top: 66px;
        padding-bottom: 48px;
        background-color: #FFFFFF;

        .footList {
            margin-top: 58px;
            line-height: 20px;
            grid-template-columns: repeat(4, auto);
            grid-column-gap: 80px;

            .footItem {

                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

}

.MuiLink-root.active {
    color: #9B96FF !important;
}

.MuiLink-root:hover {
    color: #9B96FF !important;
}

@media only screen and (max-width: 980px) {
    .footer {
    
        .footer-view {    
            .footList {
                grid-template-columns: repeat(2, auto);
                grid-column-gap: 40px;
                grid-row-gap: 24px;
            }
        }
    }
}