.dialogBg {
  width: 600px;
  max-width: 100%;
  line-height: 20px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 80px;
    background: #9b96ff;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #2f2d6c;
    border-radius: 2px;
  }
  .font-24 {
    line-height: 36px;
  }

  .font-30 {
    line-height: 45px;
  }

  .box {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 30px 24px 30px 30px;
    background: #1C0057;

    .playBtn,
    .closeBtn {
      width: 100%;
      height: 60px;
      border-radius: 9px;
    }

    .playBtn {
      background: linear-gradient(269.84deg, #4155e1 11.31%, #9f6fd7 84.67%);
    }

    .closeBtn {
      border: 1px solid rgba(255, 255, 255, 0.16);
    }
  }
}

.close {
  position: absolute;
  top: 0;
  right: -70px;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
}

.MuiDialog-paper {
  border-radius: 10px !important;
  margin: 0 !important;
  overflow-y: unset !important;
}

@media only screen and (max-width: 680px) {
  .dialogBg {
    width: 90vw;
  }

  .close {
    right: 0;
  }
}
